<template>
  <div class="login_cover">
    <div class="login-box">
      <div class="left-img">
        <img src="@/assets/images/Full-Logo-Gold.png" class="site-logo" />
        <div class="email-call-info">
          <ul>
            <li>
              <a href="mailto:mreis@srlife.net">
                <i class="fal fa-envelope"></i> mreis@srlife.net
              </a>
            </li>
            <li>
              <a href="tel:8777778808">
                <i class="fal fa-phone-alt"></i> 877.777.8808
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="login-form">
        <validation-observer ref="loginObserver" v-slot="{ handleSubmit }">
          <v-form
            class="login-column"
            @submit.prevent="
              handleSubmit(!isForgotPassword ? login : forgotPassword)
            "
            ref="loginForm"
          >
            <div class="login-form-fild" v-if="!isForgotPassword">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Username"
                      v-model="username"
                      class="form-control"
                      placeholder="Username"
                      required
                      outlined
                      :disabled="loading"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      dark
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <v-text-field
                      label="Password"
                      class="form-control"
                      placeholder="Password"
                      required
                      outlined
                      :disabled="loading"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      dark
                      data-vv-validate-on="change"
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    >
                    </v-text-field>
                  </validation-provider>
                  <div class="forgot-password my-3 text-right font-weight-bold">
                    <a
                      href="#"
                      @click="isForgotPassword = true"
                      v-if="!isForgotPassword"
                      >Forgot password?</a
                    >
                    <a href="#" @click="isForgotPassword = false" v-else>
                      Back to Login</a
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="login-form-fild" v-else>
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Enter your email"
                      v-model="forgotEmail"
                      class="form-control"
                      placeholder="Username"
                      required
                      outlined
                      :disabled="loading"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      dark
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                  <div class="forgot-password my-3 text-right font-weight-bold">
                    <a href="#" @click="isForgotPassword = false">
                      Back to Login</a
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="text-center">
              <v-btn
                class="signin-btn"
                type="submit"
                color="primary"
                dark
                rounded
                :disabled="loading"
                :loading="loading"
                v-if="!isForgotPassword"
              >
                Sign In
              </v-btn>
              <v-btn
                v-else
                class="signin-btn"
                type="submit"
                color="primary"
                dark
                rounded
                :disabled="loading"
                :loading="loading"
              >
                Send
              </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import { redirectPageName, secureStorage } from "@/utils/helpers";
import Footer from "@/components/Footer.vue";
import AuthService from "../services/AuthService";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Footer,
  },
  data() {
    return {
      showPassword: false,
      username: "",
      password: "",
      loading: false,
      error: [],
      isForgotPassword: false,
      forgotEmail: "",
    };
  },
  methods: {
    ...mapActions("auth", ["authRequest"]),
    async login() {
      this.loading = true;
      await this.authRequest({
        username: this.username,
        password: this.password,
        connectionId:
          this.$store.state.auth.connectionId ||
          secureStorage().getItem("ConnectionKey"),
      })
        .then((response) => {
          if (response.data.isSuccess) {
            this.loading = false;
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: "LoggedIn Successfully",
                type: "success",
              },
              { root: true }
            );
            // secureStorage().setItem(
            //   "ConnectionKey",
            //   this.$store.state.auth.connectionId
            // );
            this.$store.dispatch("notificationsMessage/getNotificationMessage");
            setTimeout(() => {
              this.$router.push({ name: redirectPageName() });
            }, 500);
            //
          } else {
            this.loading = false;
            this.error = response.data;
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: "Invalid username or password",
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: error.response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        });
    },
    async forgotPassword() {
      try {
        this.loading = true;
        const response = await AuthService.forgotPassword({
          email: this.forgotEmail,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.isForgotPassword = false;
          this.forgotEmail = "";
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
